import { addDoc, Timestamp } from "firebase/firestore"
import { genericConverter } from "hooks/firestore/FirestoreDocument"
import { getBasketsRef } from "hooks/firestore/getRefs"
import { getPrivateUser } from "hooks/firestore/simple/usePrivateUser"
import { getCurrentUser } from "hooks/localstate/auth/useCurrentUser"
import { useShopId } from "hooks/localstate/context/useShopId"
import { setLocalStorage, useLocalStorage } from "hooks/localstate/localstorage/useLocalStorage"
import { useAdminStorefront } from "hooks/localstate/url/useAdminStorefront"
import { useB2BStorefront } from "hooks/localstate/url/useB2BStorefront"
import Basket, { B2BBasket, InternalBasket, NormalBasket } from "types/firestore/basket"

const openingBasketMutex: Record<string, boolean | undefined> = {}

export function generateBasketKey(shopId: string | undefined, adminStorefront?: boolean): string {
  return `gp_shop_${shopId}_basket${adminStorefront ? "_admin" : ""}`
}

export function useBasketId(createBasket?: boolean) {
  const shopId = useShopId()
  const adminStorefront = useAdminStorefront()
  const isB2B = useB2BStorefront()
  const [basketId, setBasketId] = useLocalStorage(generateBasketKey(shopId, adminStorefront))

  //Ensure only one basket is created, if there is none
  if (createBasket && !basketId && shopId && !openingBasketMutex[shopId]) {
    openingBasketMutex[shopId] = true
    openNewBasket(shopId, setBasketId, adminStorefront, isB2B)
  }
  if (basketId && shopId && openingBasketMutex[shopId]) {
    openingBasketMutex[shopId] = false
  }

  return basketId
}

//TODO: This is not where one would expect it
export const unsetBasketId = (shopId: string, admin?: boolean) => {
  setLocalStorage(generateBasketKey(shopId, admin), "")
}

export const openNewBasket = async (
  shopId: string,
  setBasketId: (basketId: string) => void,
  internalBasket?: boolean,
  isB2B?: boolean
) => {
  let newBasket: InternalBasket | B2BBasket | NormalBasket
  if (internalBasket) {
    // Internal basket
    newBasket = {
      orderMode: undefined,
      internal: true,
      isB2B: false,
      paymentMethod: "cash",
      shop: shopId,
      status: "open",
      started: Timestamp.fromDate(new Date()),
    } as InternalBasket
  } else if (isB2B) {
    const user = getCurrentUser()

    if (!user) {
      return
    }

    const privateUser = await getPrivateUser(user.uid)
    // B2B basket
    newBasket = {
      orderMode: undefined,
      internal: false,
      isB2B: true,
      paymentMethod: "cash",
      shop: shopId,
      status: "open",
      started: Timestamp.fromDate(new Date()),
      address: {
        name: "",
        address: "",
        city: "",
        zipCode: "",
        longitude: 0,
        latitude: 0,
      },
      b2b: {
        vatId: privateUser?.b2b?.vatId,
        address: privateUser
          ? privateUser.b2b?.address
          : {
              name: "",
              address: "",
              city: "",
              zipCode: "",
              longitude: 0,
              latitude: 0,
            },
      },
    } as B2BBasket
  } else {
    // Normal basket
    newBasket = {
      orderMode: undefined,
      internal: false,
      isB2B: false,
      paymentMethod: undefined,
      shop: shopId,
      status: "open",
      started: Timestamp.fromDate(new Date()),
    } as NormalBasket
  }

  const newDoc = await addDoc(getBasketsRef(shopId).withConverter(genericConverter<Basket>()), newBasket)
  setBasketId(newDoc.id)
}
